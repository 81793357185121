import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginBody, RegisterBody, ResetPasswordBody, User } from '@app/models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AuthApi extends ApiService {
  static authPrefix = '/auth';

  public signIn$(body: LoginBody): Observable<User> {
    return this.post(`${AuthApi.authPrefix}/login`, body) as Observable<User>;
  }

  public registerUser$(body: RegisterBody): Observable<any> {
    return this.post(`${AuthApi.authPrefix}/register`, body);
  }

  public activateUser(body: ResetPasswordBody): Observable<any> {
    return this.post(`${AuthApi.authPrefix}/activate`, body);
  }

  public resetPasswordRequest(email: string): Observable<any> {
    return this.post(`${AuthApi.authPrefix}/resetPasswordRequest`, { email });
  }

  public resetPassword(body: ResetPasswordBody): Observable<any> {
    return this.post(`${AuthApi.authPrefix}/resetPassword`, body);
  }

  public logout(): Observable<any> {
    return this.post(`${AuthApi.authPrefix}/logout`);
  }

  public getMyselfDetails$(): Observable<User> {
    return this.get(`${AuthApi.authPrefix}/myself`);
  }
}
