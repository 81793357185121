import { inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { MonoTypeOperatorFunction, Observable, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { environment } from '../../environments/environment';
import { StorageService } from '@app/services/storage.service';
import { UserStoreService } from '@app/services/user-store.service';

type RequestOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};

@Injectable({ providedIn: 'root' })
export abstract class ApiService {
  private readonly BASE_PATH: string = `${environment.basePath}${environment.apiPostfix}`;
  private readonly http = inject(HttpClient);
  private readonly messageService = inject(MessageService);
  private readonly router = inject(Router);
  private readonly storageService = inject(StorageService);

  protected post<TBody extends object, TResponse extends object>(
    url: string,
    body: TBody = {} as TBody,
    options?: RequestOptions,
  ): Observable<TResponse> {
    return this.http
      .post<TResponse>(`${this.BASE_PATH}${url}`, body, options)
      .pipe(this.parseResponse<TResponse>(url));
  }

  protected put<TBody extends object, TResponse extends object>(
    url: string,
    body: TBody = {} as TBody,
    options?: RequestOptions,
  ): Observable<TResponse> {
    return this.http
      .put<TResponse>(`${this.BASE_PATH}${url}`, body, options)
      .pipe(this.parseResponse<TResponse>(url));
  }

  protected get<TResponse extends object>(
    url: string,
    sortById: boolean = false,
    options?: RequestOptions,
  ): Observable<TResponse> {
    return this.http
      .get<TResponse>(`${this.BASE_PATH}${url}`, options!)
      .pipe(this.parseResponse<TResponse>(url, sortById));
  }

  protected delete<TResponse extends object>(
    url: string,
    options?: RequestOptions,
  ): Observable<TResponse> {
    return this.http
      .delete<TResponse>(`${this.BASE_PATH}${url}`, options)
      .pipe(this.parseResponse<TResponse>(url));
  }

  private parseResponse<TResponse>(
    url: string,
    sortById: boolean = false,
    respSchema?: TResponse,
  ): MonoTypeOperatorFunction<TResponse> {
    return tap({
      next: (response: TResponse) => {
        if (sortById) {
          return (response as Partial<{ id: number }>[]).sort(
            (a, b) => Number(b.id) - Number(a.id),
          );
        }
        return response;
      },
      error: (error: any) => {
        if (error?.error && typeof error?.error === 'object') {
          this.messageService.addAll(
            Object.keys(error.error).map((k, i) => ({
              // key: 'error-msg',
              severity: 'error',
              summary: `API ERROR - ${k}`,
              detail: error.error[k] ?? 'Error',
              life: 10000,
            })),
          );
        } else {
          this.messageService.add({
            severity: 'error',
            summary: `API ERROR`,
            detail: error.error ?? 'Error',
            life: 10000,
          });
        }

        if (error.status === 401) {
          this.router.navigate(['/auth', 'login']).then(() => {
            this.storageService.remove(UserStoreService.TOKEN_KEY);
          });
        }
      },
    });
  }
}
