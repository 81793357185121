import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { User } from '@app/models';
import { AuthApi } from '@app/api/auth.api';
import { StorageService } from '@app/services/storage.service';

@Injectable({ providedIn: 'root' })
export class UserStoreService {
  public static readonly TOKEN_KEY: string = 'token';

  private readonly authApi = inject(AuthApi);
  private readonly storage = inject(StorageService);

  private readonly userStore$: BehaviorSubject<any> = new BehaviorSubject(null);
  private readonly userToken$: BehaviorSubject<null | string> =
    new BehaviorSubject<null | string>(null);
  private readonly userLoggedState$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  constructor() {
    this.userToken$.next(this.storage.get(UserStoreService.TOKEN_KEY));
  }

  public setUserDetails(userDetail: User): void {
    this.userStore$.next(userDetail);
  }

  public getUserDetails(): User {
    return this.userStore$.value;
  }

  public getUserDetails$(): Observable<User> {
    return this.userStore$.asObservable();
  }

  public setUserLogged(isLogged: boolean): void {
    this.userLoggedState$.next(isLogged);
  }

  public get isUserLogged(): boolean {
    return this.userLoggedState$.value;
  }

  public get isUserLogged$(): Observable<boolean> {
    return this.userLoggedState$.asObservable();
  }

  public get token(): string | null {
    return this.userToken$.value;
  }

  public set token(token: string | null) {
    this.storage.set(UserStoreService.TOKEN_KEY, token);
    this.userToken$.next(token);
  }

  public get token$(): Observable<string | null> {
    return this.userToken$.asObservable();
  }

  public checkInitState() {
    this.authApi.getMyselfDetails$().subscribe((res) => {
      this.setUserDetails(res);
      this.setUserLogged(true);
    });
  }
}
